import React, { Component } from "react"
import { Helmet } from "react-helmet"

import Container from "../common/helpers/Container.js"
import ContainerWithBg from "../common/helpers/ContainerWithBg.js"

import Page from "../common/elements/Page.js"
import BlogPost from "../common/elements/BlogPost.js"

import LatestPosts from "../common/elements/LatestPosts.js"
import Categories from "../common/elements/Categories.js"
import Newsletter from "../common/elements/Newsletter.js"
import MateriaisEducativosCta from "../common/elements/MateriaisEducativosCta.js"
import Input from "../common/components/input.js"
import SubmitSearch from "../common/components/SubmitSearch"

export default class SearchPage extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      loading: false,
      postsData: null,
    }
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.fetchData(params['s'])
  }

  handleOnSubmit = e => {
    e.preventDefault();
    this.fetchData(document.querySelector("#pesquisar_no_blog").value)
  }

  fetchData = searchString => {
    this.setState({ loading: true })
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () =>  {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        this.setState({ loading: false, postsData: JSON.parse(xhr.response) });
      } else if (xhr.readyState === XMLHttpRequest.DONE) {
        this.setState({ loading: false });
        this.handleServerResponse(false, 'Erro ao enviar o formulário');
      }
    };
    xhr.open("GET", `https://backoffice.comunicacaointegrada.com.br/wp-json/wp/v2/searchResults?s=${searchString}`, true);
    xhr.send();
  }

  render () {
    const { loading, postsData } = this.state
    return (
      <Page>
        <Helmet>
          <link rel="preload" as="image" href="../images/bg-blog-hero.png"></link>
        </Helmet>
        <ContainerWithBg
          classBgName="bg-pattern bg-pattern-gradient-soft bg-image-blog-hero"
          className="container-md tac mh-27r line-lenght-large ai-c jc-c dfc c-white p-r z1"
        >
          <h1 className="h1-egg mb-15r">Resultado da Busca</h1>
        </ContainerWithBg>
        <Container className="container-xg mt-4r mb-4r">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8">
                <div className="pe-lg-8">
                  <h2 className="c-purple h2 mb-3r">Notícias</h2>
                  <div className="row">
                    { loading &&
                      <div className="tac">
                        <span className="spinner large c-purple sending" role="status" aria-hidden="true"></span>
                      </div>
                    }
                    { postsData && !loading &&
                      postsData.map((node, index) => (
                        <div className="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4 d-flex justify-content-center align-items-stretch mb-4" key={`searchResult-${index}`}>
                          <BlogPost
                            tag={node.categories[0].name}
                            date={node.post_date}
                            toPath={node.pathname}
                            toPathTag={node.categories[0].uri}
                            title={node.post_title}
                            excerpt={node.post_excerpt}
                            imageSrc={node.image ? node.image : null}
                            classInner="blog-index"
                          />
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="pt-5">
                  <div className="row">
                    <div className="order-2 order-lg-1 col-12">
                    <div className="mt-8 mt-lg-0">
                      <h3 className="fw-700 mb-2r h4">Busca</h3>
                      <form onSubmit={this.handleOnSubmit} disabled={loading}>
                        <div className="d-xl-flex">
                          <Input 
                            name="s"
                            noLabel
                            placeholder="Pesquisar no blog..."
                            required
                            fieldsetClass="w-100 mb-2 mb-xl-0 me-xl-2"
                            inputClass="w-100"
                          />
                          <SubmitSearch label="Pesquisar" hasIcon fill="white" className="ms-auto size-p btn bg-color-purple" />
                        </div>
                      </form>    
                    </div>
                    </div>
                    <div className="order-1 order-lg-2 col-12">
                      <Newsletter />
                    </div>
                    <div className="order-3 order-lg-3 col-sm-6 col-lg-12">
                      <LatestPosts />
                    </div>
                    <div className="order-4 order-lg-4 col-sm-6 col-lg-12">
                      <Categories />
                    </div>
                    <div className="order-5 order-lg-5 col">
                      <MateriaisEducativosCta />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Page>
    )
  }
}